.reports-table-div {
  margin-top: 1%;
}

.reports-table-header {
  background-color: #e7e7e7 !important;
}

.pagination {
  float: right;
}

.pagination li {
  list-style-type: none;
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination li.active {
  background-color: #da291c;
  color: white;
}

.pagination li:hover:not(.active) {
  background-color: #ddd;
}

.button-style {
  background-color: #da291c !important;
  color: white !important;
  width: 150px;
  height: 35px;
}

.input-label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.logout {
  float: right;
}

.rs-picker-toggle-value {
  color: #da291c !important;
}

.select-box {
  z-index: 10;
}
